*,
  ::before,
  ::after {
  box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #e5e7eb;
  /* 2 */
}

::before,
  ::after {
  --tw-content: "";
}

/*
    1. Use a consistent sensible line-height in all browsers.
    2. Prevent adjustments of font size after orientation changes in iOS.
    3. Use a more readable tab size.
    4. Use the user's configured `sans` font-family by default.
    5. Use the user's configured `sans` font-feature-settings by default.
    6. Use the user's configured `sans` font-variation-settings by default.
    */

html {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  /* 3 */
  tab-size: 4;
  /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  /* 4 */
  font-feature-settings: normal;
  /* 5 */
  font-variation-settings: normal;
  /* 6 */
}

/*
    1. Remove the margin in all browsers.
    2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
    */

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

/*
    1. Add the correct height in Firefox.
    2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
    3. Ensure horizontal rules are visible by default.
    */

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 1px;
  /* 3 */
}

/*
    Add the correct text decoration in Chrome, Edge, and Safari.
    */

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

/*
    Remove the default font size and weight for headings.
    */

/* h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  } */

/*
    Reset links to optimize for opt-in styling instead of opt-out.
    */

a {
  color: inherit;
  text-decoration: inherit;
}

p {
  letter-spacing: normal;
}

/*
    Add the correct font weight in Edge and Safari.
    */

b,
  strong {
  font-weight: bolder;
}

/*
    1. Use the user's configured `mono` font family by default.
    2. Correct the odd `em` font sizing in all browsers.
    */

code,
  kbd,
  samp,
  pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/*
    Add the correct font size in all browsers.
    */

small {
  font-size: 80%;
}

/*
    Prevent `sub` and `sup` elements from affecting the line height in all browsers.
    */

sub,
  sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
    1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
    2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
    3. Remove gaps between table borders by default.
    */

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

/*
    1. Change the font styles in all browsers.
    2. Remove the margin in Firefox and Safari.
    3. Remove default padding in all browsers.
    */

/* button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    -webkit-font-feature-settings: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
  } */

/*
    Remove the inheritance of text transform in Edge and Firefox.
    */

button,
  select {
  text-transform: none;
}

/*
    1. Correct the inability to style clickable types in iOS and Safari.
    2. Remove default button styles.
    */

/* button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;

    background-color: transparent;

    background-image: none;

  } */

/*
    Use the modern Firefox focus style for all focusable elements.
    */

:-moz-focusring {
  outline: auto;
}

/*
    Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
    */

:-moz-ui-invalid {
  box-shadow: none;
}

/*
    Add the correct vertical alignment in Chrome and Firefox.
    */

progress {
  vertical-align: baseline;
}

/*
    Correct the cursor style of increment and decrement buttons in Safari.
    */

::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
  height: auto;
}

/*
    1. Correct the odd appearance in Chrome and Safari.
    2. Correct the outline style in Safari.
    */

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/*
    Remove the inner padding in Chrome and Safari on macOS.
    */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
    1. Correct the inability to style clickable types in iOS and Safari.
    2. Change font properties to `inherit` in Safari.
    */

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
    Add the correct display in Chrome and Safari.
    */

summary {
  display: list-item;
}

/*
    Removes the default spacing and border for appropriate elements.
    */

/* blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  } */

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
  ul,
  menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
    Reset default styling for dialogs.
    */

dialog {
  padding: 0;
}

/*
    Prevent resizing textareas horizontally by default.
    */

textarea {
  resize: vertical;
}

/*
    1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
    2. Set the default placeholder color to the user's configured gray 400 color.
    */

input::placeholder,
  textarea::placeholder {
  opacity: 1;
  /* 1 */
  color: #9ca3af;
  /* 2 */
}

/*
    Set the default cursor for buttons.
    */

button,
  [role="button"] {
  cursor: pointer;
}

/*
    Make sure disabled buttons don't get the pointer cursor.
    */

:disabled {
  cursor: default;
}

/*
    1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
    2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
       This can trigger a poorly considered lint error in some tools but is included by design.
    */

img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

/*
    Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
    */

img,
  video {
  max-width: 100%;
  height: auto;
}

/* Make elements with the HTML hidden attribute stay hidden by default */

[hidden] {
  display: none;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.tw-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.tw-pointer-events-none {
  pointer-events: none;
}

.tw-fixed {
  position: fixed;
}

.tw-absolute {
  position: absolute;
}

.tw-relative {
  position: relative;
}

.tw-inset-y-0 {
  top: 0px;
  bottom: 0px;
}

.-tw-bottom-\[20px\] {
  bottom: -20px;
}

.-tw-bottom-\[50px\] {
  bottom: -50px;
}

.-tw-bottom-\[60px\] {
  bottom: -60px;
}

.-tw-left-\[40px\] {
  left: -40px;
}

.-tw-left-\[60px\] {
  left: -60px;
}

.-tw-right-\[30px\] {
  right: -30px;
}

.-tw-right-\[40px\] {
  right: -40px;
}

.-tw-right-\[60px\] {
  right: -60px;
}

.-tw-top-8 {
  top: -2rem;
}

.-tw-top-\[20px\] {
  top: -20px;
}

.-tw-top-\[25px\] {
  top: -25px;
}

.-tw-top-\[30px\] {
  top: -30px;
}

.-tw-top-\[40px\] {
  top: -40px;
}

.-tw-top-\[60px\] {
  top: -60px;
}

.tw-bottom-0 {
  bottom: 0px;
}

.tw-bottom-2 {
  bottom: 0.5rem;
}

.tw-bottom-6 {
  bottom: 1.5rem;
}

.tw-bottom-\[2px\] {
  bottom: 2px;
}

.tw-left-0 {
  left: 0px;
}

.tw-left-1\/2 {
  left: 50%;
}

.tw-left-\[50\%\] {
  left: 50%;
}

.tw-right-0 {
  right: 0px;
}

.tw-right-1 {
  right: 0.25rem;
}

.tw-right-2 {
  right: 0.5rem;
}

.tw-right-6 {
  right: 1.5rem;
}

.tw-right-\[2px\] {
  right: 2px;
}

.tw-start-0 {
  inset-inline-start: 0px;
}

.tw-top-0 {
  top: 0px;
}

.tw-top-1 {
  top: 0.25rem;
}

.tw-top-1\/2 {
  top: 50%;
}

.tw-top-3 {
  top: 0.75rem;
}

.tw-top-6 {
  top: 1.5rem;
}

.tw-top-\[20px\] {
  top: 20px;
}

.tw-isolate {
  isolation: isolate;
}

.tw--z-10 {
  z-index: -10;
}

.tw-z-10 {
  z-index: 10;
}

.tw-z-50 {
  z-index: 50;
}

.tw-z-\[100\] {
  z-index: 100;
}

.tw-z-\[10\] {
  z-index: 10;
}

.tw-z-\[1\] {
  z-index: 1;
}

.tw-z-\[2\] {
  z-index: 2;
}

.tw-z-\[5\] {
  z-index: 5;
}

.tw-col-span-2 {
  grid-column: span 2 / span 2;
}

.tw-col-span-6 {
  grid-column: span 6 / span 6;
}

.tw-m-5 {
  margin: 1.25rem;
}

.tw-m-auto {
  margin: auto;
}

.-tw-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}

.-tw-mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}

.-tw-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.-tw-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}

.tw-mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.tw-mb-2 {
  margin-bottom: 0.5rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-mb-5 {
  margin-bottom: 1.25rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-mb-auto {
  margin-bottom: auto;
}

.tw-me-2 {
  margin-inline-end: 0.5rem;
}

.tw-me-3 {
  margin-inline-end: 0.75rem;
}

.tw-ml-1 {
  margin-left: 0.25rem;
}

.tw-ml-2 {
  margin-left: 0.5rem;
}

.tw-ml-3 {
  margin-left: 0.75rem;
}

.tw-ml-auto {
  margin-left: auto;
}

.tw-mr-2 {
  margin-right: 0.5rem;
}

.tw-mr-3 {
  margin-right: 0.75rem;
}

.tw-ms-auto {
  margin-inline-start: auto;
}

.tw-mt-1 {
  margin-top: 0.25rem;
}

.tw-mt-10 {
  margin-top: 2.5rem;
}

.tw-mt-16 {
  margin-top: 4rem;
}

.tw-mt-2 {
  margin-top: 0.5rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-mt-5 {
  margin-top: 1.25rem;
}

.tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-mt-\[100px\] {
  margin-top: 100px;
}

.tw-mt-\[80px\] {
  margin-top: 80px;
}

.tw-block {
  display: block;
}

.tw-inline-block {
  display: inline-block;
}

.tw-flex {
  display: flex;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-grid {
  display: grid;
}

.tw-hidden {
  display: none;
}

.tw-aspect-\[1155\/678\] {
  aspect-ratio: 1155/678;
}

.tw-h-11 {
  height: 2.75rem;
}

.tw-h-4 {
  height: 1rem;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-6 {
  height: 1.5rem;
}

.tw-h-8 {
  height: 2rem;
}

.tw-h-9 {
  height: 2.25rem;
}

.tw-h-\[100px\] {
  height: 100px;
}

.tw-h-\[150px\] {
  height: 150px;
}

.tw-h-\[160px\] {
  height: 160px;
}

.tw-h-\[190px\] {
  height: 190px;
}

.tw-h-\[20px\] {
  height: 20px;
}

.tw-h-\[220px\] {
  height: 220px;
}

.tw-h-\[230px\] {
  height: 230px;
}

.tw-h-\[270px\] {
  height: 270px;
}

.tw-h-\[275px\] {
  height: 275px;
}

.tw-h-\[280px\] {
  height: 280px;
}

.tw-h-\[290px\] {
  height: 290px;
}

.tw-h-\[295px\] {
  height: 295px;
}

.tw-h-\[2px\] {
  height: 2px;
}

.tw-h-\[350px\] {
  height: 350px;
}

.tw-h-\[35px\] {
  height: 35px;
}

.tw-h-\[395px\] {
  height: 395px;
}

.tw-h-\[450px\] {
  height: 450px;
}

.tw-h-\[465px\] {
  height: 465px;
}

.tw-h-\[4px\] {
  height: 4px;
}

.tw-h-\[80px\] {
  height: 80px;
}

.tw-h-\[85vh\] {
  height: 85vh;
}

.tw-h-auto {
  height: auto;
}

.tw-h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.tw-h-full {
  height: 100%;
}

.tw-h-screen {
  height: 100vh;
}

.tw-max-h-fit {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

.tw-min-h-0 {
  min-height: 0px;
}

.tw-w-2\/3 {
  width: 66.666667%;
}

.tw-w-4 {
  width: 1rem;
}

.tw-w-5 {
  width: 1.25rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-9 {
  width: 2.25rem;
}

.tw-w-\[100px\] {
  width: 100px;
}

.tw-w-\[200px\] {
  width: 200px;
}

.tw-w-\[220px\] {
  width: 220px;
}

.tw-w-\[235px\] {
  width: 235px;
}

.tw-w-\[260px\] {
  width: 260px;
}

.tw-w-\[270px\] {
  width: 270px;
}

.tw-w-\[280px\] {
  width: 280px;
}

.tw-w-\[310px\] {
  width: 310px;
}

.tw-w-\[320px\] {
  width: 320px;
}

.tw-w-\[35px\] {
  width: 35px;
}

.tw-w-\[360px\] {
  width: 360px;
}

.tw-w-\[50px\] {
  width: 50px;
}

.tw-w-\[60px\] {
  width: 60px;
}

.tw-w-\[72\.1875rem\] {
  width: 72.1875rem;
}

.tw-w-\[90vw\] {
  width: 90vw;
}

.tw-w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.tw-w-full {
  width: 100%;
}

.tw-w-screen {
  width: 100vw;
}

.tw-min-w-0 {
  min-width: 0px;
}

.tw-max-w-2xl {
  max-width: 42rem;
}

.tw-max-w-3xl {
  max-width: 48rem;
}

.tw-max-w-fit {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.tw-max-w-full {
  max-width: 100%;
}

.tw-max-w-md {
  max-width: 28rem;
}

.tw-max-w-screen-lg {
  max-width: 1024px;
}

.tw-max-w-screen-md {
  max-width: 768px;
}

.tw-max-w-screen-xl {
  max-width: 1280px;
}

.tw-max-w-sm {
  max-width: 24rem;
}

.tw-max-w-xs {
  max-width: 20rem;
}

.tw-flex-auto {
  flex: 1 1 auto;
}

.tw-flex-none {
  flex: none;
}

.tw-flex-shrink-0 {
  flex-shrink: 0;
}

.tw-origin-center {
  transform-origin: center;
}

.tw-origin-right {
  transform-origin: right;
}

.-tw-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-x-8 {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw--translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-8 {
  --tw-translate-x: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-rotate-45 {
  --tw-rotate: -45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-list-none {
  list-style-type: none;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-items-center {
  align-items: center;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-gap-1 {
  gap: 0.25rem;
}

.tw-gap-10 {
  gap: 2.5rem;
}

.tw-gap-16 {
  gap: 4rem;
}

.tw-gap-2 {
  gap: 0.5rem;
}

.tw-gap-3 {
  gap: 0.75rem;
}

.tw-gap-4 {
  gap: 1rem;
}

.tw-gap-5 {
  gap: 1.25rem;
}

.tw-gap-8 {
  gap: 2rem;
}

.tw-gap-x-2 {
  column-gap: 0.5rem;
}

.tw-gap-x-4 {
  column-gap: 1rem;
}

.tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}

.tw-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.tw-self-center {
  align-self: center;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-x-auto {
  overflow-x: auto;
}

.tw-overflow-y-auto {
  overflow-y: auto;
}

.tw-overflow-y-hidden {
  overflow-y: hidden;
}

.tw-overflow-y-scroll {
  overflow-y: scroll;
}

.tw-whitespace-nowrap {
  white-space: nowrap;
}

.tw-rounded {
  border-radius: 0.25rem;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-lg {
  border-radius: 0.5rem;
}

.tw-rounded-md {
  border-radius: 0.375rem;
}

.tw-rounded-xl {
  border-radius: 0.75rem;
}

.tw-rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.tw-rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.tw-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.tw-rounded-bl {
  border-bottom-left-radius: 0.25rem;
}

.tw-border {
  border-width: 1px;
}

.tw-border-0 {
  border-width: 0px;
}

.tw-border-2 {
  border-width: 2px;
}

.tw-border-\[1px\] {
  border-width: 1px;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-b-2 {
  border-bottom-width: 2px;
}

.tw-border-l-2 {
  border-left-width: 2px;
}

.tw-border-dashed {
  border-style: dashed;
}

.tw-border-dotted {
  border-style: dotted;
}

.tw-border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.tw-border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.tw-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.tw-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.tw-border-transparent {
  border-color: transparent;
}

.tw-border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.tw-bg-\[\#9E00FF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(158 0 255 / var(--tw-bg-opacity));
}

.tw-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.tw-bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.tw-bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.tw-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.tw-bg-transparent {
  background-color: transparent;
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.tw-bg-white\/5 {
  background-color: rgb(255 255 255 / 0.05);
}

.tw-bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}

.tw-bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}

.tw-from-\[\#b58074\] {
  --tw-gradient-from: #b58074 var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(181 128 116 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.tw-to-\[\#8284de\] {
  --tw-gradient-to: #8284de var(--tw-gradient-to-position);
}

.tw-object-contain {
  object-fit: contain;
}

.tw-object-cover {
  object-fit: cover;
}

.tw-p-0 {
  padding: 0px;
}

.tw-p-1 {
  padding: 0.25rem;
}

.tw-p-1\.5 {
  padding: 0.375rem;
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-2 {
  padding: 0.5rem;
}

.tw-p-2\.5 {
  padding: 0.625rem;
}

.tw-p-3 {
  padding: 0.75rem;
}

.tw-p-3\.5 {
  padding: 0.875rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-6 {
  padding: 1.5rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-p-\[30px\] {
  padding: 30px;
}

.tw-px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.tw-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.tw-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.tw-px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.tw-px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.tw-px-\[6px\] {
  padding-left: 6px;
  padding-right: 6px;
}

.tw-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tw-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.tw-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.tw-py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.tw-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.tw-py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tw-py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.tw-py-\[90px\] {
  padding-top: 90px;
  padding-bottom: 90px;
}

.tw-pb-2 {
  padding-bottom: 0.5rem;
}

.tw-pb-\[90px\] {
  padding-bottom: 90px;
}

.tw-ps-3 {
  padding-inline-start: 0.75rem;
}

.tw-pt-\[50px\] {
  padding-top: 50px;
}

.tw-text-left {
  text-align: left;
}

.tw-text-center {
  text-align: center;
}

.tw-font-\[\'Roboto\'\] {
  font-family: 'Roboto';
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-leading-5 {
  line-height: 1.25rem;
}

.tw-tracking-tight {
  letter-spacing: -0.025em;
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.tw-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.tw-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.tw-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.tw-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.tw-text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.tw-text-inherit {
  color: inherit;
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.tw-no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.tw-opacity-0 {
  opacity: 0;
}

.tw-opacity-100 {
  opacity: 1;
}

.tw-opacity-30 {
  opacity: 0.3;
}

.tw-opacity-\[0\.15\] {
  opacity: 0.15;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.tw-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tw-outline-1 {
  outline-width: 1px;
}

.tw-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.tw-ring-inset {
  --tw-ring-inset: inset;
}

.tw-ring-black\/10 {
  --tw-ring-color: rgb(0 0 0 / 0.1);
}

.tw-ring-white\/10 {
  --tw-ring-color: rgb(255 255 255 / 0.1);
}

.tw-blur-3xl {
  --tw-blur: blur(64px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.tw-duration-100 {
  transition-duration: 100ms;
}

.tw-duration-200 {
  transition-duration: 200ms;
}

.tw-duration-300 {
  transition-duration: 300ms;
}

.tw-duration-75 {
  transition-duration: 75ms;
}

.tw-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.tw-ease-linear {
  transition-timing-function: linear;
}

.tw-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.hover\:-tw-translate-x-\[1px\]:hover {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:-tw-translate-y-\[1px\]:hover {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-translate-x-\[1px\]:hover {
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:tw-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:tw-bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.hover\:tw-bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.hover\:tw-text-black:hover {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.hover\:tw-text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.hover\:tw-underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.hover\:tw-shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:tw-border-\[1px\]:focus {
  border-width: 1px;
}

.focus\:tw-border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:tw-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:tw-ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:tw-ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

.disabled\:tw-opacity-80:disabled {
  opacity: 0.8;
}

.tw-group:hover .group-hover\:tw-w-full {
  width: 100%;
}

.tw-group:hover .group-hover\:tw-opacity-100 {
  opacity: 1;
}

.tw-group:hover .group-hover\:tw-opacity-\[0\.15\] {
  opacity: 0.15;
}

:is([dir="rtl"] .rtl\:tw-text-right) {
  text-align: right;
}

@media (prefers-color-scheme: dark) {
  .dark\:tw-border-none {
    border-style: none;
  }

  .dark\:tw-border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgb(209 213 219 / var(--tw-border-opacity));
  }

  .dark\:tw-border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgb(75 85 99 / var(--tw-border-opacity));
  }

  .dark\:tw-bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgb(55 65 81 / var(--tw-bg-opacity));
  }

  .dark\:tw-text-gray-300 {
    --tw-text-opacity: 1;
    color: rgb(209 213 219 / var(--tw-text-opacity));
  }

  .dark\:tw-text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .dark\:tw-placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgb(156 163 175 / var(--tw-placeholder-opacity));
  }

  .dark\:tw-placeholder-white\/70::placeholder {
    color: rgb(255 255 255 / 0.7);
  }

  .dark\:hover\:tw-bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  }

  .dark\:hover\:tw-bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(31 41 55 / var(--tw-bg-opacity));
  }

  .dark\:focus\:tw-border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgb(59 130 246 / var(--tw-border-opacity));
  }

  .dark\:focus\:tw-ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
  }
}

@media (min-width: 640px) {
  .sm\:tw-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:tw-inline-flex {
    display: inline-flex;
  }

  .sm\:tw-h-\[210px\] {
    height: 210px;
  }

  .sm\:tw-h-\[300px\] {
    height: 300px;
  }

  .sm\:tw-w-auto {
    width: auto;
  }

  .sm\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .md\:tw-bottom-3 {
    bottom: 0.75rem;
  }

  .md\:tw-order-1 {
    order: 1;
  }

  .md\:tw-order-2 {
    order: 2;
  }

  .md\:tw-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:tw-mb-0 {
    margin-bottom: 0px;
  }

  .md\:tw-block {
    display: block;
  }

  .md\:tw-flex {
    display: flex;
  }

  .md\:tw-grid {
    display: grid;
  }

  .md\:tw-h-\[110px\] {
    height: 110px;
  }

  .md\:tw-h-\[230px\] {
    height: 230px;
  }

  .md\:tw-h-\[240px\] {
    height: 240px;
  }

  .md\:tw-h-\[290px\] {
    height: 290px;
  }

  .md\:tw-h-\[300px\] {
    height: 300px;
  }

  .md\:tw-h-\[310px\] {
    height: 310px;
  }

  .md\:tw-h-\[315px\] {
    height: 315px;
  }

  .md\:tw-h-\[330px\] {
    height: 330px;
  }

  .md\:tw-h-\[480px\] {
    height: 480px;
  }

  .md\:tw-h-full {
    height: 100%;
  }

  .md\:tw-w-\[120px\] {
    width: 120px;
  }

  .md\:tw-w-\[270px\] {
    width: 270px;
  }

  .md\:tw-w-\[310px\] {
    width: 310px;
  }

  .md\:tw-w-\[320px\] {
    width: 320px;
  }

  .md\:tw-w-\[350px\] {
    width: 350px;
  }

  .md\:tw-w-full {
    width: 100%;
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:tw-flex-row {
    flex-direction: row;
  }

  .md\:tw-flex-col {
    flex-direction: column;
  }

  .md\:tw-justify-end {
    justify-content: flex-end;
  }

  .md\:tw-justify-between {
    justify-content: space-between;
  }

  .md\:tw-gap-16 {
    gap: 4rem;
  }

  .md\:tw-overflow-y-auto {
    overflow-y: auto;
  }

  .md\:tw-overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:tw-p-8 {
    padding: 2rem;
  }

  .md\:tw-text-left {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .lg\:tw-order-1 {
    order: 1;
  }

  .lg\:tw-order-2 {
    order: 2;
  }

  .lg\:tw-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:tw-mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:tw-mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:tw-mt-0 {
    margin-top: 0px;
  }

  .lg\:tw-block {
    display: block;
  }

  .lg\:tw-flex {
    display: flex;
  }

  .lg\:tw-hidden {
    display: none;
  }

  .lg\:tw-h-\[356px\] {
    height: 356px;
  }

  .lg\:tw-w-10 {
    width: 2.5rem;
  }

  .lg\:tw-w-auto {
    width: auto;
  }

  .lg\:tw-w-full {
    width: 100%;
  }

  .lg\:tw-max-w-4xl {
    max-width: 56rem;
  }

  .lg\:tw-scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .lg\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:tw-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:tw-flex-row {
    flex-direction: row;
  }

  .lg\:tw-justify-start {
    justify-content: flex-start;
  }

  .lg\:tw-gap-3 {
    gap: 0.75rem;
  }

  .lg\:tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:tw-p-16 {
    padding: 4rem;
  }

  .lg\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:tw-py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:tw-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:tw-text-left {
    text-align: left;
  }
}

@media (min-width: 1280px) {
  .xl\:tw-bottom-5 {
    bottom: 1.25rem;
  }

  .xl\:tw-h-\[250px\] {
    height: 250px;
  }

  .xl\:tw-h-\[420px\] {
    height: 420px;
  }

  .xl\:tw-h-\[550px\] {
    height: 550px;
  }

  .xl\:tw-w-\[360px\] {
    width: 360px;
  }

  .xl\:tw-w-\[420px\] {
    width: 420px;
  }

  .xl\:tw-w-\[500px\] {
    width: 500px;
  }

  .xl\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
